import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql, Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import React from 'react';
import { Carousel, CarouselItem, Col, Container, Row } from 'react-bootstrap';

import globals from '../../globals';
import EscapeCard from '../components/escapeCard/escapeCard';
import Layout from '../components/layout/layout';
import NewsletterBanner from '../components/newsletterBanner/newsletterBanner';
import PostCard from '../components/postCard/postCard';
import SeoPress from '../components/seoPress/seoPress';

const IndexPage = ({ data }) => {
  let headerBackgroundFluidImageStack = [
    data.featuredPost.nodes[0].postBlog.gallery[0].localFile.childImageSharp
      .fluid || data.fallbackImage.childImageSharp.fluid,
    globals.backgroundOverlay,
  ].reverse();

  const rows = [...Array(Math.ceil(data.guidePosts.nodes.length / 3))];
  const guideRows = rows.map((row, idx) =>
    data.guidePosts.nodes.slice(idx * 3, idx * 3 + 3)
  );

  return (
    <Layout navbarOnWhite={false}>
      <SeoPress
        seo={data.wpSitePage.seo}
        title={data.wpSitePage.title}
        uri={``}
      />

      <main>
        <BackgroundImage Tag="header" fluid={headerBackgroundFluidImageStack}>
          <div
            className="d-flex w-100"
            style={{ minHeight: `${globals.minHeaderHeight} !important` }}
          >
            <Container className="d-flex align-items-center pt-7 pb-6">
              <div className="d-flex flex-column">
                <h2 className="display-1 h1 mb-3 text-white">
                  {data.featuredPost.nodes[0].title}
                </h2>
                <div>
                  <Link
                    to={`/${globals.blogURI}${data.featuredPost.nodes[0].uri}`}
                    className="btn btn-primary w-auto"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </Container>
          </div>
        </BackgroundImage>

        <section className="py-5">
          <h2 className="text-center mb-4">
            {
              data.wpSitePage.pageSiteHome.siteHomeEscapesSection
                .featuredEscapesHeadline
            }
          </h2>
          <Container fluid={true} className="px-3 px-lg-4">
            {data.featuredEscapes.nodes.length > 0 ? (
              <Row>
                {data.featuredEscapes.nodes.map((escape, index) => (
                  <Col xs={12} md={6} xl={4} className="mb-4" key={index}>
                    <EscapeCard escape={escape} />
                  </Col>
                ))}

                <Col xs={12} md={6} xl={4} className="mb-4">
                  <Link to={'/escapes/'} className="more-card">
                    <div>
                      <div className="more-card-icon">
                        <FontAwesomeIcon icon={['fal', 'compass']} />
                      </div>
                      <br />
                      See The Latest Escapes
                    </div>
                  </Link>
                </Col>
              </Row>
            ) : (
              <>No Escapes Found</>
            )}
          </Container>
        </section>

        <hr className="w-50 my-0" />

        <section className="py-6">
          <Container>
            <h1 className="text-center mb-0" style={{ textTransform: 'unset' }}>
              {data.wpSitePage.seo.metaDesc}
            </h1>
          </Container>
        </section>

        <hr className="w-50 my-0" />

        <section className="py-5">
          <h2 className="text-center mb-4">
            {
              data.wpSitePage.pageSiteHome.siteHomeJournalSection
                .recentGuidesHeadline
            }
          </h2>
          <Container fluid={true}>
            {data.guidePosts.nodes.length > 0 ? (
              <Carousel interval={null} indicators={true} controls={false}>
                {guideRows.map((post, index) => (
                  <CarouselItem key={index}>
                    <Row className="justify-content-center">
                      {post.map((post, index) => (
                        <Col xs={12} md={6} lg={4} className="mb-4" key={index}>
                          <PostCard post={post} />
                        </Col>
                      ))}
                    </Row>
                  </CarouselItem>
                ))}
              </Carousel>
            ) : (
              <>No Guides Found</>
            )}
          </Container>
        </section>

        <hr className="w-50 my-0" />

        <section className="py-5">
          <h2 className="text-center mb-4">
            {
              data.wpSitePage.pageSiteHome.siteHomeJournalSection
                .recentPostsHeadline
            }
          </h2>
          <Container fluid={true}>
            {data.recentPosts.nodes.length > 0 ? (
              <Row>
                {data.recentPosts.nodes.map((post, index) => (
                  <Col xs={12} md={6} lg={4} className="mb-4" key={index}>
                    <PostCard post={post} />
                  </Col>
                ))}

                <Col xs={12} md={6} lg={4} className="mb-4">
                  <Link to={'/journal/'} className="more-card">
                    <div>
                      <div className="more-card-icon">
                        <FontAwesomeIcon icon={['fal', 'passport']} />
                      </div>
                      <br />
                      {
                        data.wpSitePage.pageSiteHome.siteHomeJournalSection
                          .recentPostsButtonText
                      }
                    </div>
                  </Link>
                </Col>
              </Row>
            ) : (
              <>No Recent Posts Found</>
            )}
          </Container>
        </section>

        <section className="pt-2 pb-6 pt-md-4 pb-md-7">
          <NewsletterBanner />
        </section>
      </main>
    </Layout>
  );
};

export const query = graphql`
  query {
    wpSitePage(databaseId: { eq: 25 }) {
      title
      slug
      seo {
        title
        metaDesc
        metaKeywords
        canonical
        opengraphUrl
        opengraphType
        opengraphTitle
        opengraphDescription
        opengraphImage {
          localFile {
            publicURL
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          localFile {
            publicURL
          }
        }
      }
      pageSiteHome {
        siteHomeHeader {
          tagline
          backgroundVideo {
            localFile {
              publicURL
            }
          }
          backgroundImage {
            localFile {
              publicURL
            }
          }
        }
        siteHomeJournalSection {
          recentPostsHeadline
          recentPostsButtonText
          recentGuidesHeadline
        }
        siteHomeEscapesSection {
          featuredEscapesHeadline
          newestEscapesHeadline
        }
      }
    }
    wp {
      sharedSiteContent {
        siteNewsletterSignup {
          headline
        }
      }
    }
    featuredPost: allWpPost(
      filter: { tags: { nodes: { elemMatch: { slug: { eq: "featured" } } } } }
      sort: { fields: date, order: DESC }
      limit: 1
    ) {
      nodes {
        ...cardPost
      }
    }
    recentPosts: allWpPost(
      sort: { fields: date, order: DESC }
      limit: 5
      filter: { postBlog: { postFormat: { ne: "Guide" } } }
    ) {
      nodes {
        ...cardPost
      }
    }
    guidePosts: allWpPost(
      filter: { postBlog: { postFormat: { eq: "Guide" } } }
      sort: { fields: date, order: DESC }
      limit: 6
    ) {
      nodes {
        ...cardPost
      }
    }
    featuredEscapes: allWpEscape(
      filter: { tags: { nodes: { elemMatch: { slug: { eq: "featured" } } } } }
      sort: { fields: date, order: DESC }
      limit: 5
    ) {
      nodes {
        ...cardEscape
      }
    }
    fallbackImage: file(relativePath: { eq: "backgrounds/bg-fallback.jpg" }) {
      ...imageFluidExtraLarge
    }
  }
`;

export default IndexPage;
